import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface RecessionProofEbookProps {}

const RecessionProofEbook: FC<RecessionProofEbookProps> = () => {
  useEffect(() => {
    track('eBook Viewed');
    navigate('/pdfs/ebook.pdf');
  }, []);

  return <></>;
};

export default RecessionProofEbook;
